import React from 'react';
import './style.css';

const AboutDrawer = props => {

    const assignedClasses = ['about about--animate'];

    if(props.aboutCanvas) {
        let scrollTop = document.getElementById("aboutScroll");
        scrollTop.scrollTop = 0;
        assignedClasses.push('active');

    }
    return (
        <div className={assignedClasses.join(' ')}>
            <div id="aboutScroll" className="about-scroll">
                <div className="about-wrapper">
                    <div className="about-header"></div>
                    <div className="about-content">
                        <p>銀河，台灣網路最美麗的存在⋯⋯<br />創立至今已逾20年，堅持原創、理想與專業的道路<br />不譁眾取寵、不虛妄欺騙、我們20年實事求是，堅持一磚一瓦親手砌成<br />永遠走在趨勢前面，多年來我們不僅獨創模式，更訂定網路行銷產業的相關標準<br />銀河人執著而真誠，重承諾、講信用、好公益，我們答應的，一定全力以赴<br />與我們合作者樂，與我們併肩者安，與我們同行者歡，與我們競爭者⋯⋯很難</p>
                        <p>銀河以自研技術協助眾多商業品牌客戶，在激烈市場競爭中，以創新的行銷模式領先對手，在危機發生前即時預警，在策略枯竭時提供建議，我們珍惜客戶委托，使命必達。</p>
                        <p>銀河自創立華人首家網路電台以來，始終堅持原創與保存台灣數位影像與音樂內容，歷經2000年網路泡沫化風暴，2008全球金融海嘯，我們投資台灣數位內容的信念從未動搖。</p>
                        <p>2012年繼Kuro與EZpeer<a target="_blank" rel="noopener noreferrer" href="https://www1.tipo.gov.tw/public/AttachmentORG/03%E8%AB%96%E8%BF%B03_%E5%9C%8B%E9%9A%9B%E8%88%87%E5%8F%B0%E7%81%A3P2P%E6%A1%88%E4%BB%B6%E4%B9%8B%E7%A0%94%E7%A9%B689_150.pdf">（1）</a>之後，台灣11家唱片公司聯合向台灣士林地方法院檢察署控告本公司所經營銀河網路電台侵犯其著作權乙案，歷經士林地檢署二年偵查，認定銀河網路電台毫無侵害著作權之犯行，予以不起訴處分後，11唱片公司仍向台灣高等法院檢察署聲請再議，高等法院檢署亦認定毫無侵權犯罪行為後，11家唱片公司又向士林地院要求交付審判，但11家唱片公司之交付審判請求，亦遭士林地院駁回，判決定讞，全案確定。</p>
                        <p>士林地院、士林地檢署及高等法院檢察署，均一致認定銀河網路電台無任何違反著作權法之犯行存在<a target="_blank" rel="noopener noreferrer" href="https://law.judicial.gov.tw/FJUD/data.aspx?ty=JD&id=SLDM,104%2C%E8%81%B2%E5%88%A4%2C34%2C20150723%2C1">（2）</a>。千辛萬苦經過台灣司法認證清白後的銀河網路電台，繼續秉持初衷為台灣影音數位內容努力。</p>
                        <p>銀河為支持台灣原創音樂，於2011年成立銀河愛音樂平台，協助數百位年輕詞曲創作者完成他們的流行音樂作品。於台北信義區舉辦三年銀河音樂聚活動<a target="_blank" rel="noopener noreferrer" href="https://www.iwant-music.com/musictogether.php">（3）</a>，搭建舞台邀請台灣創作樂團參與盛會，並承辦2017年台北世大運主題曲網路徵選活動<a target="_blank" rel="noopener noreferrer" href="https://fisu.iwant-in.net/">（4）</a>。更於2017-2019與中國廣播電視總台合辦"兩岸青年原創金曲大選"活動<a target="_blank" rel="noopener noreferrer" href="https://www.youth-song.com/">（5）</a>，為兩岸原創音樂人打造最高榮譽殿堂。</p>
                        <p>2015年，銀河為實踐取於社會用之社會的理念，在福特汽車與各專業人士的協助下，完成"台灣你好"的七天環島直播行動<a target="_blank" rel="noopener noreferrer" href="https://www.twnihao.com.tw/">（6）</a>，15輛車隊+65人環島超過2,000公里的壯舉。2019年台灣你好粉絲團<a target="_blank" rel="noopener noreferrer" href="https://zh-tw.facebook.com/twnihao/">（7）</a>已成為台灣NGO粉專排名第五名<a target="_blank" rel="noopener noreferrer" href="https://www.socialbakers.com/statistics/facebook/pages/total/taiwan/society/ngo">（8）</a>，2019年的環島直播更獨創全國粉絲分享會活動，與全國各地270名粉絲進行直播採訪。</p>
                        <p className="about-content__conclude">銀河的故事很精彩，過了20年還在繼續前進中，<br />如果您想與眾不同，那麼歡迎與我們同行。</p>
                        <div className="about-content__contact">
                            <p>請與銀河互動網路集團 數位行銷處聯繫：</p>
                            <p>服務電話：886-2-6600-7079，陳小姐(分機702)</p>
                            <p>服務信箱：Marketing@iwant-in.net</p>
                        </div>
                    </div>
                    <div className="about-footer"></div>
                </div>
            </div>
        </div>
    )
};

export default AboutDrawer;