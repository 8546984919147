import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { unregister } from './registerServiceWorker';
// import ReactGA from 'react-ga';
import './index.css';
import App from './containers/App';


// // ReactGA.initialize('UA-17661439-3');
// ReactGA.initialize([
//     {trackingId: 'G-D5XLLCJ0TQ'},
//     {trackingId: 'UA-17661439-3'}
// ]);

// ReactGA.pageview(window.location.pathname + window.location.search);

class Official extends Component {
    render() {
        return (
            <App />
        )
    }
}

ReactDOM.render(
    <Official />,
 document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();