import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.css";
import './style.css';

class Dot extends Component {
    render() {
        return (
            <div className="dotCollect dotCollect--link">
                <ul className="dotWrapper">
                    {this.props.anchors.map((anchors, key) => {
                        return <li key={key} className="dotLink"><NavLink to={`/${this.props.innerLink}/${key+1}`}>{anchors}</NavLink></li>
                    })}
                </ul>
            </div>
        )
    }

}

export default Dot;