import React, { Component } from 'react';
import posed from 'react-pose';
import Socialgroup from "../../../../components/Socialgroup/index";
import Sidebar from "../../../../components/Sidebar/index";
import "bootstrap/dist/css/bootstrap.css";
import "./style.css";


const Content = posed.div({
    enter: { staggerChildren: 400 },
    exit: { staggerChildren: 20, staggerDirection: -1 }
});
const AnimateDown = posed.div({
    enter: { y: 0, opacity: 1, transition: () => ({ type: 'tween', delay: 800 }) },
    exit: { y: 10, opacity: 0 }
});

class PageService extends Component {

    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.bgRef = React.createRef();
        this.state = {
            videoActive: false,
            updateCheck: this.props.animateNum
        }
    }

    videoPlay = () => {
        if (this.state.updateCheck === this.props.animateNum && this.props.animateActive === 'enter') {
            this.videoRef.current.play();
        }
    };
    componentDidMount() {
        this.videoPlay();
    }
    componentDidUpdate() {
        this.videoPlay();
    }

    render() {
        return (
            <div id={`service${this.props.animateNum}`} className={`service service-${this.props.animateNum}`}>
                <Content
                  className={this.props.positionTrigger === true ? 'serviceMain active' : 'serviceMain'}
                  initialPose="exit"
                  pose={this.props.animateActive}>
                    <AnimateDown className="service-title" initialPose="exit" pose={this.props.animateActive}>
                        <h3>{this.props.mainUnit.title}</h3>
                    </AnimateDown>
                    <AnimateDown className="service-subtitle" initialPose="exit" pose={this.props.animateActive}>
                        <p>{this.props.mainUnit.subtitle}</p>
                    </AnimateDown>
                    <AnimateDown className="service-social" initialPose="exit" pose={this.props.animateActive}>
                        <Socialgroup Socialgroup={this.props.socialUnit} />
                    </AnimateDown>
                </Content>
                <Sidebar
                    animateNum={this.props.animateNum}
                    sidebarUnit={this.props.sidebarUnit}
                    contactUnit={this.props.contactUnit}
                    sidebar={this.props.sidebar}
                    pose={this.props.positionTrigger}
                    poseTrigger={this.props.sidebarClickHandler}
                />
                <div className="sec-video object-fit__container">
                    <video className="background-video object-fit__cover" ref={this.videoRef} data-object-fit="cover" preload={'auto'} loop muted playsInline>
                        <source src={this.props.videoUrl} type="video/mp4" />
                        <source src={this.props.videoUrl} type="video/ogg" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        );
    }
};

export default PageService;