import React from 'react';
import { NavLink } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import { Transition } from 'react-transition-group';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "bootstrap/dist/css/bootstrap.css";
import './style.css';

const ContactDrawer = props => {
    const assignedClasses = ['contactDrawer'];

    if (props.contactCanvas) {
        let scrollTop = document.getElementById("contactDrawer");
        scrollTop.scrollTop = 0;
        assignedClasses.push('active');
    }

    const duration = 500;

    const defaultStyle = {
        transition: `all ${duration}ms 300ms`,
        opacity: 0,
        top: `20px`
    }

    const transitionStyles = {
        entering: { opacity: 0, top: `20px` },
        entered: { opacity: 1, top: `0` },
        exiting: { opacity: 0, top: `20px` },
        exited: { opacity: 0, top: `20px` }
    }
    return (
        <div id="contactDrawer" className={assignedClasses.join(' ')}>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs="12" sm="6" xl="4">
                        <Transition in={props.contactCanvas} timeout={duration}>
                            {state => (
                                <div className="contact-unit">
                                    <h3 style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                    }}>數位行銷中心</h3>
                                    <div className="contact-wrap" style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                    }}>
                                        <ul>
                                            <li onClick={props.drawerClickHandler}><a href='#/service/1'>口碑行銷</a></li>
                                            <li onClick={props.drawerClickHandler}><a href='#/service/2'>掠奪行銷</a></li>
                                            <li onClick={props.drawerClickHandler}><a href='#/service/3'>網紅行銷</a></li>
                                        </ul>
                                        <ul>
                                            <li onClick={props.drawerClickHandler}><a href='#/service/5'>網路保全監測</a></li>
                                            <li onClick={props.drawerClickHandler}><a href='#/service/6'>公益行銷</a></li>
                                            <li onClick={props.drawerClickHandler}><a href='#/service/7'>影音行銷</a></li>
                                        </ul>
                                    </div>
                                    <div className="contact-wrap" style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                    }}>
                                        <div className="contact-btn"><span>TEL</span> (02) 6600-7079 分機 702(陳小姐) </div>
                                        <div className="contact-btn"><span>FAX</span> (02)6601-1960</div>
                                        <div className="contact-btn btn-blue"><span>MAIL</span> Marketing@iwant-in.net</div>
                                    </div>
                                </div>
                            )}
                        </Transition>
                    </Col>
                    <Col xs="12" sm="6" xl="4">
                        <Transition in={props.contactCanvas} timeout={duration + 100}>
                            {state => (
                                <div className="contact-unit">
                                    <h3 style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                    }}>數位內容中心</h3>
                                    <div className="contact-wrap" style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                    }}>
                                        <ul>
                                            <li onClick={props.drawerClickHandler}><a href='#/homemade/2'>台灣，你好！</a></li>
                                            <li onClick={props.drawerClickHandler}><a href='#/homemade/3'>天天好味</a></li>
                                        </ul>
                                        <ul></ul>
                                    </div>
                                    <div className="contact-wrap" style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                    }}>
                                        <div className="contact-btn"><span>TEL</span> (02) 6600-7079 分機 876</div>
                                        <div className="contact-btn"><span>FAX</span> (02)6601-1960</div>
                                        <div className="contact-btn btn-blue"><span>MAIL</span> iwant-service@iwant-in.net</div>
                                    </div>
                                </div>
                            )}
                        </Transition>
                    </Col>
                    <Col xs="12" sm="6" xl="4">
                        <Transition in={props.contactCanvas} timeout={duration + 200}>
                            {state => (
                                <div className="contact-unit">
                                    <h3 style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                    }}>數位交流中心</h3>
                                    <div className="contact-wrap" style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                    }}>
                                        <ul>
                                            <li onClick={props.drawerClickHandler}><a href='#/exchange/1'>兩岸青年原創金曲大選</a></li>
                                            <li onClick={props.drawerClickHandler}><a href='#/exchange/2'>海峽論壇</a></li>
                                        </ul>
                                        <ul>
                                            <li onClick={props.drawerClickHandler}><a href='#/exchange/3'>兩岸航拍大賽</a></li>
                                        </ul>
                                    </div>
                                    <div className="contact-wrap" style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                    }}>
                                        <div className="contact-btn"><span>TEL</span> (02) 6600-0357 分機 105</div>
                                        <div className="contact-btn"><span>FAX</span> (02)6601-1960</div>
                                    </div>
                                </div>
                            )}
                        </Transition>
                    </Col>

                    <Col xs="12" sm="6" xl="4">
                        <Transition in={props.contactCanvas} timeout={duration+300}>
                            {state => (
                                <div className="contact-unit">
                                    <h3 style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                    }}>銀河網路電台</h3>
                                    <div className="contact-wrap" style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                    }}>
                                        <ul>
                                            <li onClick={props.drawerClickHandler}><a href='#/homemade/1'>唱片宣傳合作</a></li>
                                            <li onClick={props.drawerClickHandler}><a href='#/homemade/1'>電影宣傳合作</a></li>
                                        </ul>
                                        <ul>
                                            <li onClick={props.drawerClickHandler}><a href='#/homemade/1'>藝人宣傳通告</a></li>
                                            <li onClick={props.drawerClickHandler}><a href='#/homemade/1'>媒體宣傳合作等</a></li>
                                        </ul>
                                    </div>
                                    <div className="contact-wrap" style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                    }}>
                                        <div className="contact-btn"><span>TEL</span> (02) 6600-7079 分機 876</div>
                                        <div className="contact-btn"><span>FAX</span> (02)6601-1960</div>
                                        <div className="contact-btn btn-blue"><span>MAIL</span> iwant-service@iwant-in.net</div>
                                    </div>
                                </div>
                            )}
                        </Transition>
                    </Col>
                    <Col xs="12" sm="6" xl="4">
                        <Transition in={props.contactCanvas} timeout={duration+400}>
                            {state => (
                                <div className="contact-unit">
                                    <h3 style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                    }}>銀河愛音樂</h3>
                                    <div className="contact-wrap" style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                    }}>
                                        <ul>
                                            <li onClick={props.drawerClickHandler}><a href='#/homemade/4'>音樂發行</a></li>
                                            <li onClick={props.drawerClickHandler}><a href='#/homemade/4'>異業合作</a></li>
                                            <li onClick={props.drawerClickHandler}><a href='#/homemade/4'>活動邀請</a></li>
                                        </ul>
                                        <ul>
                                            <li onClick={props.drawerClickHandler}><a href='#/homemade/4'>iCD音樂授權等各類合作</a></li>
                                        </ul>
                                    </div>
                                    <div className="contact-wrap" style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                    }}>
                                        <div className="contact-btn"><span>TEL</span> (02) 6600-7079 分機 109</div>
                                        <div className="contact-btn"><span>FAX</span> (02)6601-1960</div>
                                        <div className="contact-btn btn-blue"><span>MAIL</span> icd@iwant-in.net</div>
                                    </div>
                                </div>
                            )}
                        </Transition>
                    </Col>
                    <Col xs="12" sm="6" xl="4">
                        <Transition in={props.contactCanvas} timeout={duration+500}>
                            {state => (
                                <div className="contact-unit">
                                    <h3 style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                    }}>iTRY試用情報王</h3>
                                    <div className="contact-wrap" style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                    }}>
                                        <ul>
                                            <li><NavLink to={{ pathname: '/service/4', state: '4' }} onClick={props.drawerClickHandler}>素人體驗行銷</NavLink></li>
                                            <li><NavLink to={{ pathname: '/service/4', state: '4' }} onClick={props.drawerClickHandler}>試用品&兌換券提供</NavLink></li>
                                        </ul>
                                        <ul>
                                            <li><NavLink to={{ pathname: '/service/4', state: '4' }} onClick={props.drawerClickHandler}>消費情報提供</NavLink></li>
                                            <li><NavLink to={{ pathname: '/service/4', state: '4' }} onClick={props.drawerClickHandler}>異業策略合作</NavLink></li>
                                        </ul>
                                    </div>
                                    <div className="contact-wrap" style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                    }}>
                                        <div className="contact-btn"><span>TEL</span> (02) 6600-0357 分機 887</div>
                                        <div className="contact-btn"><span>FAX</span> (02)6601-1960</div>
                                        <div className="contact-btn btn-blue"><span>MAIL</span> contact@itry.com.tw</div>
                                    </div>
                                </div>
                            )}
                        </Transition>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ContactDrawer;