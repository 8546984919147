import React, { Component } from 'react';
import './style.css';



class ContactButton extends Component {

    render() {
        return (
            <div className="button-group">
                <button className="reward-button" onClick={this.props.drawerAboutHandler}>
                    關於銀河
                </button>
                <button className="contact-button" onClick={this.props.drawerContactHandler}>
                    聯絡我們
                </button>
            </div>
        )
    }
};

export default ContactButton;