import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './images/facebook.svg';
import './style.css';

class Socialgroup extends Component {
    render() {
        return (
            <div className="social-group">
                {this.props.Socialgroup.officialLink ? (<a href={this.props.Socialgroup.officialLink} className="btn-social btn-official" target="_blank" rel="noopener noreferrer">官 方 網 站</a>) : null}
                {this.props.Socialgroup.facebookLink ? (<a href={this.props.Socialgroup.facebookLink} className="btn-social btn-fb" target="_blank" rel="noopener noreferrer">facebook</a>) : null}
                {this.props.Socialgroup.youtubeLink ? (<a href={this.props.Socialgroup.youtubeLink} className="btn-social btn-yt" target="_blank" rel="noopener noreferrer">youtube</a>) : null}
            </div>
        )
    }
}

export default Socialgroup;