import React, { Component } from 'react';
import "./style.css";

class Footer extends Component {

    render() {
        return (
            <footer className="footer">
                <a className="footer-banner" href="https://www.iwant-in.net/tw/iMarketing/?p=8338"> </a>
                <div className="footer-right">
                    <p>版權所有  請尊重智慧財產權</p>
                    <p>本公司法律顧問鼎岳律師事務所 本網頁各連結標題及連結內容歸原權利人所有</p>
                    <p>Copyright © iWant-in.net Inc. All Rights Reserved.</p>
                </div>
            </footer>
        )
    }
}

export default Footer;