import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import './style.css';

class Dot extends Component {

    render() {
        return (
            <div className={this.props.dotOpen ? "dotCollect dotCollect--basic dotCollect--on" : "dotCollect dotCollect--basic dotCollect--off"}>
                <ul className="dotWrapper">
                    {this.props.anchors.map((anchors, key) => {
                        return <li key={key} className="dotClick" onClick={() => { this.props.goToPage(key) }}>{anchors}</li>
                    })}
                </ul>
            </div>
        )
    }

}

export default Dot;