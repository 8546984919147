import React, { Component } from 'react';
import { withRouter } from "react-router";
import ReactPageScroller from "react-page-scroller";
import PageHomemade from './components/Main/index';
import Dot from '../../components/Dotting/index';
import "bootstrap/dist/css/bootstrap.css";
import "./style.css"
import PageTitle01 from "./images/title/iwantradio.png";
import PageTitle02 from "./images/title/hellotaiwan.png";
import PageTitle03 from "./images/title/everydayfood.png";
import PageTitle04 from "./images/title/icd.png";
import video1 from "../../video/homemade-01.webm"
import video2 from "../../video/homemade-02.webm"
import video3 from "../../video/homemade-03.webm"
import video4 from "../../video/homemade-04.webm"

class Homemade extends Component {

    constructor(props) {
        super(props);
        this.state = {
            anchors: ["銀河網路電台", "台灣，你好！", "天天好味", "銀河愛音樂"],
            dotOpen: true,
            positionTrigger: false,
            loading: undefined,
            done: undefined,
            locationState: Number(props.location.state),
            enterHistory: Number(props.match.params.id),
            homemadeCommon: [
                {
                    indexNum: 0,
                    animateNum: 1,
                    refName: "homemade1",
                    animateActive: "enter",
                    videoUrl: video1,
                    mainUnit: {
                        title: "銀河網路電台",
                        titleimg: PageTitle01,
                        subtitle: "台灣第一家網路電台，\n最新流行娛樂影音資訊這裡找!",
                    },
                    sidebarUnit: {
                        sidebarOpen: false,
                        sidebarTitle: "銀河網路電台",
                        sidebarSubtitle: "",
                        sidebarBlock: [
                            {
                                UnitIndex: 1,
                                UnitTitle: "",
                                UnitContent: "台灣第一家網路電台，為網路產業最巨量數位內容製作團隊 收藏20年來鉅量影音、45000集節目紀錄、7000多位歌手資料 ，並持續累積中 不管是娛樂消息、影音資訊以及名人專訪……每個都不容錯過！"
                            }
                        ]
                    },
                    socialUnit: {
                        officialLink: "https://www.iwant-radio.com/",
                        facebookLink: "https://www.facebook.com/iwantradio/",
                        youtubeLink: "https://www.youtube.com/user/iwantradio"
                    },
                    contactUnit: {
                        contactTitle: "聯絡方式",
                        contactContent: "(02)6600-7079 分機876\niwant-service@iwant-in.net"
                    }
                },
                {
                    indexNum: 1,
                    animateNum: 2,
                    refName: "homemade2",
                    animateActive: "exit",
                    videoUrl: video2,
                    mainUnit: {
                        title: "台灣，你好！",
                        titleimg: PageTitle02,
                        subtitle: "發掘並呈現台灣的美好，\n為生活傳達正面力量",
                    },
                    sidebarUnit: {
                        sidebarOpen: false,
                        sidebarTitle: "台灣，你好！",
                        sidebarSubtitle: "",
                        sidebarBlock: [
                            {
                                UnitIndex: 1,
                                UnitTitle: "",
                                UnitContent: "百萬網友感心推薦，盼藉台灣風土人文，喚起人們守護台灣的心。"
                            },
                            {
                                UnitIndex: 2,
                                UnitTitle: "",
                                UnitContent: "台灣，你好！粉絲團用心製作優質內容，單月觸及400萬人，為全台灣最大旅行環島類公益粉絲團，帶領人們每日在台灣美景中翱翔。\n讓我們一起找回善良純樸的台灣精神，善待這片土地，珍惜我們自己。"
                            },
                            {
                                UnitIndex: 3,
                                UnitTitle: "",
                                UnitContent: "若您的企業一樣擁有對這片土地貢獻的精神，歡迎一同攜手加入，讓台灣更好！"
                            }
                        ]
                    },
                    socialUnit: {
                        officialLink: "https://www.twnihao.com.tw",
                        facebookLink: "https://www.facebook.com/twnihao",
                        youtubeLink: "https://www.youtube.com/c/TwnihaoTwhello"
                    },
                    contactUnit: {
                        contactTitle: "聯絡方式",
                        contactContent: "(02)6600-7079 分機876\niwant-service@iwant-in.net"
                    }
                },
                {
                    indexNum: 2,
                    animateNum: 3,
                    refName: "homemade3",
                    animateActive: "exit",
                    videoUrl: video3,
                    mainUnit: {
                        title: "天天好味",
                        titleimg: PageTitle03,
                        subtitle: "呈現台灣在地食材的創意料理，\n天天都有料理新靈感！",
                    },
                    sidebarUnit: {
                        sidebarOpen: false,
                        sidebarTitle: "天天好味",
                        sidebarSubtitle: "",
                        sidebarBlock: [
                            {
                                UnitIndex: 1,
                                UnitTitle: "",
                                UnitContent: "不管是街邊小吃還是滿漢全席，好味老師都能輕鬆做給你看！"
                            },
                            {
                                UnitIndex: 2,
                                UnitTitle: "",
                                UnitContent: "台灣在地當季食材如何結合獨家烹調秘訣，全部不藏私告訴你 讓你每天料理充滿創意吃不膩！"
                            },
                            {
                                UnitIndex: 3,
                                UnitTitle: "",
                                UnitContent: "如果您有優良食品、絕佳的烹飪器具、有趣的廚房用品，歡迎交給好味老師，讓老師替您施展料理的魔法！"
                            }
                        ]
                    },
                    socialUnit: {
                        officialLink: "",
                        facebookLink: "",
                        youtubeLink: "https://www.youtube.com/channel/UCwjzvAUDEmQssDk4y_yRD8g"
                    },
                    contactUnit: {
                        contactTitle: "聯絡方式",
                        contactContent: "(02)6600-7079 分機876\niwant-service@iwant-in.net"
                    }
                },
                {
                    indexNum: 3,
                    animateNum: 4,
                    refName: "homemade4",
                    animateActive: "exit",
                    videoUrl: video4,
                    mainUnit: {
                        title: "銀河愛音樂",
                        titleimg: PageTitle04,
                        subtitle: "提供正版好音樂，\n讓音樂的腳步不停歇",
                    },
                    sidebarUnit: {
                        sidebarOpen: false,
                        sidebarTitle: "銀河愛音樂",
                        sidebarSubtitle: "",
                        sidebarBlock: [
                            {
                                UnitIndex: 1,
                                UnitTitle: "",
                                UnitContent: "我們尋找下一代音樂人，並投資美好的原創流行音樂，包括資深音樂人、獨立樂團、網路歌手、樂器演奏家，超過200張的數位專輯已在網路上發行，播送每首歌的夢想藍圖！"
                            },
                            {
                                UnitIndex: 2,
                                UnitTitle: "",
                                UnitContent: "所有音樂提供免費下載和線上聆聽\n下載發行數量超過6, 480萬\n歌詞版MV數位製作與發行超過55首"
                            },
                            {
                                UnitIndex: 3,
                                UnitTitle: "",
                                UnitContent: "目前已有包含資深音樂人謝宇威、丁曉雯、黃連煜、韓賢光、李昀陵、荒山亮等知名音樂人，還有多名創作新人等近 150 位音樂人在此平臺發行音樂，並且徵選優秀音樂創作人、演唱歌手、編曲人進行媒合與協助完成發片，讓音樂的分享功能發揮極大作用。"
                            }
                        ]
                    },
                    socialUnit: {
                        officialLink: "https://www.iwant-music.com",
                        facebookLink: "https://www.facebook.com/iWant.iCD/",
                        youtubeLink: "https://www.youtube.com/user/iwanticd"
                    },
                    contactUnit: {
                        contactTitle: "聯絡方式",
                        contactContent: "(02)6600-7079 分機109\nicd@iwant-in.net"
                    }
                }
            ]
        }
    }

    dotOpenHandler = () => {
        this.setState(prev => ({
            dotOpen: !prev.dotOpen
        }))
    };
    sidebarClickHandler = () => {
        this.setState(prev => ({
            dotOpen: !prev.dotOpen,
            positionTrigger: !prev.positionTrigger
        }))
    };
    goToPage = (eventKey) => {
        this.reactPageScroller.goToPage(eventKey);
    };

    pageOnChange = (pageNumber) => {
        if (this.state.positionTrigger) {
            this.sidebarClickHandler();
        }
        /* 換頁時控制state來啟動動態效果 */
        const pageNum = pageNumber - 1;
        this.setState(prev => ({
            locationState: pageNumber,
            homemadeCommon: prev.homemadeCommon.map(homemadeCommon =>
                homemadeCommon.indexNum === pageNum ?
                    { ...homemadeCommon, animateActive: 'enter' } :
                    { ...homemadeCommon, animateActive: 'exit' })
        }))
    }

    componentDidMount() {
        /* loading時讀取 */
        let propsParamsMount = Number(this.props.match.params.id);
        let locationStateRender = '#/homemade/' + Number(this.state.locationState);
        if (Number(this.state.locationState) !== 0 && !Number.isNaN(this.state.locationState)) {
            if (this.state.locationState === undefined) {
                window.history.pushState('#/homemade/1', "", '#/homemade/1');
            } else {
                window.history.pushState(locationStateRender, "", locationStateRender);
            }
        } else if (Number.isNaN(this.state.locationState)) {
            window.history.pushState('#/homemade/1', "", '#/homemade/1');
        }
        this.goToPage(propsParamsMount - 1);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let stateParamsUpdate = this.state.locationState;
        let stateParamsPreUpdate = prevState.locationState;
        /* 變化state時迴避id變化 */
        if (stateParamsPreUpdate !== undefined && stateParamsUpdate !== undefined) {
            /* 迴避兩者皆為空值的狀態 */
            if (stateParamsPreUpdate !== stateParamsUpdate) {
                /* 若與初始值不同 */
                this.goToPage(stateParamsUpdate);
            } else {
                if (this.props.history.action === "PUSH") {
                    if (prevProps.location.state !== this.props.location.state) {
                        let NumberState = Number(this.props.location.state);
                        this.goToPage(NumberState - 1);
                    }
                }
            }
        }
        /* 處理網址於其他頁面Unmount時可能消失的問題 */

    }
    render() {
        let locationStateRender = '#/homemade/' + Number(this.state.locationState);
        if (Number(this.state.locationState) !== 0 && !Number.isNaN(this.state.locationState)) {
            if (this.state.locationState === undefined) {
                window.history.pushState('#/homemade/1', "", '#/homemade/1');
            } else {
                window.history.pushState(locationStateRender, "", locationStateRender);
            }
        } else if (Number.isNaN(this.state.locationState)) {
            window.history.pushState('#/homemade/1', "", '#/homemade/1');
        }
        return(
            <React.Fragment>
                <ReactPageScroller ref={c => this.reactPageScroller = c} pageOnChange={this.pageOnChange}>
                    {this.state.homemadeCommon.map((homemade, key) => {
                        return <PageHomemade
                            key={key}
                            animateNum={homemade.animateNum}
                            animateActive={homemade.animateActive}
                            refName={homemade.refName}
                            videoUrl={homemade.videoUrl}
                            mainUnit={homemade.mainUnit}
                            sidebarUnit={homemade.sidebarUnit}
                            contactUnit={homemade.contactUnit}
                            socialUnit={homemade.socialUnit}
                            dotOpen={this.dotOpenHandler}
                            positionTrigger={this.state.positionTrigger}
                            sidebarClickHandler={this.sidebarClickHandler}　/>
                    })}
                </ReactPageScroller>
                <Dot anchors={this.state.anchors} goToPage={this.goToPage} dotOpen={this.state.dotOpen} innerLink={this.state.innerLink}></Dot>
            </React.Fragment>
        );
    }
}

export default withRouter(Homemade);