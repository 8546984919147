import React, { Component } from 'react';
import posed from 'react-pose';
import "bootstrap/dist/css/bootstrap.css";
import "./style.css";


const SidebarMove = posed.div({
    enter: { transition: () => ({ type: 'tween', delay: 800 }) },
    exit: {}
});

class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.assignedClasses = ['sidebar'];
    }

    render() {

        if (this.props.pose) {
            this.assignedClasses = 'sidebar active';
        } else {
            this.assignedClasses = "sidebar"
        }

        return (
            <SidebarMove className={this.assignedClasses} initialPose="exit" pose={this.props.pose}>
                <div className="sidebar-btn" onClick={this.props.poseTrigger}></div>
                <div className="sidebar-main">
                    <div className="sidebar-wrapper">
                        <div className="sidebar-title">
                            <h3>{this.props.sidebarUnit.sidebarTitle}</h3>
                            <span>{this.props.sidebarUnit.sidebarSubtitle}</span>
                        </div>
                        <hr/>
                        <div className="sidebar-content">
                            {this.props.sidebarUnit.sidebarBlock.map((sidebarContent,key) => {
                                return (
                                    <div key={key} className={`contentUnit contentUnit-${sidebarContent.UnitIndex}`}>
                                        <h4>{sidebarContent.UnitTitle}</h4>
                                        <p>{sidebarContent.UnitContent}</p>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="sidebar-contact">
                            <h4>{this.props.contactUnit.contactTitle}</h4>
                            <p>{this.props.contactUnit.contactContent}</p>
                        </div>
                    </div>
                </div>
            </SidebarMove>
        );
    }
}

export default React.memo(Sidebar);