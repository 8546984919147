import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Home from '../../containers/Home';
import Service from '../../containers/Service';
import Exchange from '../../containers/Exchange';
import Homemade from '../../containers/Homemade';
import SideDrawer from '../../components/mainDrawer';
import ContactDrawer from '../../components/contactDrawer';
import AboutDrawer from '../../components/aboutDrawer';
import './style.css';


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      SideDrawerOpen: false,
      ContactDrawerOpen: false,
      AboutDrawerOpen: false,
      ServiceOpen: false,
      navCommon: [
        {
          index: "1",
          title: "數位行銷中心",
          enTitle: "service",
          anchors: ["口碑行銷", "掠奪行銷", "網紅行銷", "素人體驗", "網路保全監測", "公益行銷", "影音行銷", "iMarketing"]
        },
        {
          index: "2",
          title: "數位內容中心",
          enTitle: "homemade",
          anchors: ["銀河網路電台", "台灣，你好！", "天天好味", "銀河愛音樂"]
        },
        {
          index: "3",
          title: "數位交流中心",
          enTitle: "exchange",
          anchors: ["兩岸青年原創金曲大選", "海峽論壇", "兩岸航拍大賽"]
        }
      ]
    }
  }

  drawerToggleClickHandler = () => {
    if (this.state.ContactDrawerOpen || this.state.AboutDrawerOpen) {
      this.setState((prevState) => {
        return {
          SideDrawerOpen: false,
          ContactDrawerOpen: false,
          AboutDrawerOpen: false
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          SideDrawerOpen: !prevState.SideDrawerOpen,
          ContactDrawerOpen: false,
          AboutDrawerOpen: false
        };
      });
    }

  };
  drawerContactClickHandler = () => {
    this.setState((prevState) => {
      return {
        ContactDrawerOpen: !prevState.ContactDrawerOpen,
        SideDrawerOpen: false,
        AboutDrawerOpen: false
      };
    });
  };
  drawerAboutClickHandler = () => {
    this.setState((prevState) => {
      return {
        AboutDrawerOpen: !prevState.AboutDrawerOpen,
        SideDrawerOpen: false,
        ContactDrawerOpen: false
      };
    });
  };
  scrollHandler = () => {
    this.setState((prevState) => {
      return {
        sectScroll: !prevState.sectScroll,
      };
    });
  };
  allRemoveClickHandler = () => {
    this.setState(() => {
      return {
        SideDrawerOpen: false,
        ContactDrawerOpen: false,
        AboutDrawerOpen: false
      };
    });
  };
  goToPage = (eventKey) => {
    this.reactPageScroller.goToPage(eventKey);
  };

  render() {
    const { SideDrawerOpen, ContactDrawerOpen, AboutDrawerOpen } = this.state;
    window.onresize = function () {
      document.body.height = window.innerHeight;
    }
    window.onresize(); // called to initially set the height.

    const ANIMATION_MAP = {
      PUSH: 'forward',
      POP: 'back'
    }
    return (
      <div id="site-container">
        <Header
          drawerClickHandler={this.drawerToggleClickHandler}
          drawerContactHandler={this.drawerContactClickHandler}
          drawerAboutHandler={this.drawerAboutClickHandler}
          allRemoveClickHandler={this.allRemoveClickHandler}
          sideAnimate={this.state.SideDrawerOpen}
          contactAnimate={this.state.ContactDrawerOpen}
          aboutAnimate={this.state.AboutDrawerOpen}
        />
        <Router>
          <div id="content-container" className={SideDrawerOpen === true ? 'canvasMove left' : 'canvasMove'}>
            <Route render={({location, history}) => (
              <TransitionGroup
                className={'router-wrapper'}
                childFactory={child => React.cloneElement(
                  child,
                  { classNames: ANIMATION_MAP[history.action] }
                )}
              >
                <CSSTransition
                  timeout={500}
                  key={location.pathname}
                >
                  <Switch location={location} history={history}>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/service/:id' component={Service} />
                    <Route exact path='/exchange/:id' component={Exchange} />
                    <Route exact path='/homemade/:id' component={Homemade} />
                  </Switch>
                </CSSTransition>
                <SideDrawer sideCanvas={SideDrawerOpen} drawerClickHandler={this.drawerToggleClickHandler} drawerContactHandler={this.drawerContactClickHandler} drawerAboutHandler={this.drawerAboutClickHandler} goToPage={this.goToPage} location={location}></SideDrawer>
              </TransitionGroup>
            )}/>
          </div>
          <ContactDrawer contactCanvas={ContactDrawerOpen} drawerContactHandler={this.drawerContactClickHandler} drawerClickHandler={this.drawerToggleClickHandler}/>
          <AboutDrawer aboutCanvas={AboutDrawerOpen}/>
        </Router>
        <Footer />
      </div >
  )}
}

export default App;
