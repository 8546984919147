import React, { Component } from 'react';
import MainButton from './components/MainButton'
import ContactButton from './components/ContactButton'
import './style.css';

class Header extends Component {
    render() {
        return (
            <header className="sidenav">
                <nav className="sidenav__navigation">
                    <div className="sidenav_navigation-toggle">
                        <MainButton
                            click={this.props.drawerClickHandler}
                            sideAnimate={this.props.sideAnimate}
                            contactAnimate={this.props.contactAnimate}
                            aboutAnimate={this.props.aboutAnimate} />
                        <div onClick={this.props.allRemoveClickHandler} className="sidenav__logo">
                            <a href="./"> </a>
                        </div>
                    </div>
                    <div className="sidenav_navigation-items">
                        <ContactButton
                            drawerContactHandler={this.props.drawerContactHandler}
                            drawerAboutHandler={this.props.drawerAboutHandler}
                            contactAnimate={this.props.contactAnimate}
                            aboutAnimate={this.props.aboutAnimate} />
                    </div>
                </nav>

            </header>
        )
    }
};

export default Header;