import React, { Component } from 'react';
import ReactPageScroller from "react-page-scroller";
import Pageunit from './components/HomeVideo/index'
import "bootstrap/dist/css/bootstrap.css";
import "./style.css";
import video1 from "../../video/home-01.webm"
import video2 from "../../video/home-02.webm"
import video3 from "../../video/home-03.webm"
import video4 from "../../video/home-04.webm"

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchors: ["數位行銷中心", "數位交流中心", "數位內容中心", "聯絡我們"],
            locationState: Number(props.location.state),
            enterHistory: 0,
            indexCommon: [
                {
                    indexNum: 0,
                    url: video1,
                    title: "數 位 行 銷 中 心",
                    subtitle: "口碑行銷 ／ 掠奪行銷 ／ 網紅行銷 ／ 素人體驗 ／ 網路保全監測 ／ 公益行銷 ／ 影音行銷 ／ iMarketing",
                    contentlineOne: "銀河擁有亞洲唯一最專業的數位消費者行銷服務機制，",
                    contentlineTwo: "自2000年首先投入數位消費者行銷領域至今已20年，",
                    contentlineThree: "提供國內外數百種品牌最專業的「數位消費者行銷」服務。",
                    contentlineFour: "",
                    animateNum: 1,
                    animateActive: 'enter',
                    innerLink: 'service',
                    anchors: ["口碑行銷", "掠奪行銷", "網紅行銷", "素人體驗", "網路保全監測", "公益行銷", "影音行銷", "iMarketing"]
                },
                {
                    indexNum: 1,
                    url: video2,
                    title: "數 位 內 容 中 心",
                    subtitle: "銀河網路電台 ／ 台灣，你好！ ／ 天天好味 ／ 銀河愛音樂",
                    contentlineOne: "1998年銀河為華人數位內容產業開始耕耘，寫下華人流行音樂內容的序章。",
                    contentlineTwo: "22年歲月無休止的投入，結合媒體專長與數位技術能力，",
                    contentlineThree: "銀河數位內容中心累積的龐大資料世界，",
                    contentlineFour: "在華人網路流行文化的數位貧瘠中傲然獨立。",
                    animateNum: 2,
                    animateActive: 'exit',
                    innerLink: 'homemade',
                    anchors: ["銀河網路電台", "台灣，你好！", "天天好味", "銀河愛音樂"]
                },
                {
                    indexNum: 2,
                    url: video3,
                    title: "數 位 交 流 中 心",
                    subtitle: "兩岸青年原創金曲大選 ／ 海峽論壇 ／ 兩岸航拍大賽",
                    contentlineOne: "「銀河交流中心」重視未來華人青年文創產業的發展，",
                    contentlineTwo: "致力於促進兩岸三地青年學子文化創意與學術交流，",
                    contentlineThree: "於2007年率先打造兩岸大學生雲端數位文化交流平台伊始，",
                    contentlineFour: "讓華人青年在共同舞台上盡情揮灑彼此無限的創意，",
                    contentlineFive: "蔚然成為兩岸青年最大的文化交流舞台。",
                    animateNum: 3,
                    animateActive: 'exit',
                    innerLink: 'exchange',
                    anchors: ["兩岸青年原創金曲大選", "海峽論壇", "兩岸航拍大賽"]
                },
                {
                    indexNum: 3,
                    url: video4,
                    title: "聯 絡 銀 河",
                    subtitle: "",
                    contentlineOne: "連絡電話 (02)6600-7079 分機 702 (陳小姐)",
                    contentlineTwo: "MAIL Marketing@iwant-in.net",
                    animateNum: 4,
                    animateActive: 'exit',
                    innerLink: '/',
                    anchors: []
                }
            ]
        }
    }
    goToPage = (eventKey) => {
        this.reactPageScroller.goToPage(eventKey);
    };
    pageOnChange = (pageNumber) => {
        const pageNum = pageNumber - 1;
        this.setState(prev => ({
            indexCommon: prev.indexCommon.map(indexCommon =>
                indexCommon.indexNum === pageNum ?
                    { ...indexCommon, animateActive: 'enter' } :
                    { ...indexCommon, animateActive: 'exit' })
        }))
    }
    componentDidMount() {
        this.setState(prev => ({
            indexCommon: prev.indexCommon.map(indexCommon => indexCommon.indexNum === 0 ? { ...indexCommon, animateActive: 'enter' } : indexCommon)
        }))
    }
    render () {

        return (
            <React.Fragment>
                <ReactPageScroller ref={c => this.reactPageScroller = c} pageOnChange={this.pageOnChange}>
                    {this.state.indexCommon.map((home, key) => {
                        return <Pageunit
                            key={key}
                            animateNum={home.animateNum}
                            url={home.url}
                            title={home.title}
                            subtitle={home.subtitle}
                            contentlineOne={home.contentlineOne}
                            contentlineTwo={home.contentlineTwo}
                            contentlineThree={home.contentlineThree}
                            contentlineFour={home.contentlineFour}
                            contentlineFive={home.contentlineFive}
                            animateCt={home.animateActive}
                            videoTrigger={home.videoActive}
                            innerLink={home.innerLink}
                            anchors={home.anchors}
                            videoScrollDown={this.goToPage}
                            />
                    })}
                </ReactPageScroller>
            </React.Fragment>
        );
    }

}


export default Home;