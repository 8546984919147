import React, { Component } from 'react';
import { Transition } from 'react-transition-group';
import './style.css';


class SideDrawer extends Component {

    render() {

        let duration = 300;
        const titleUnitStyle = {
            transition: `all ${duration}ms 300ms`,
            transform: `matrix(1, 0, 0, 1, 30, 0)`,
            opacity: 0
        }
        const listUnitStyle = {
            transition: `all ${duration}ms 700ms`,
            opacity: 0,
            left: `50px`
        }
        const listUnitTransitionStyles = {
            entering: { opacity: 0, left: `50px` },
            entered: { opacity: 1, left: `0` },
            exiting: { opacity: 0, left: `50px` },
            exited: { opacity: 0, left: `50px` }
        }
        const titleUnitTransitionStyles = {
            entering: { transform: `matrix(1, 0, 0, 1, 30, 0)`, opacity: 0 },
            entered: { transform: `matrix(1, 0, 0, 1, 0, 0)`, opacity: 1 },
            exiting: { transform: `matrix(1, 0, 0, 1, 30, 0)`, opacity: 0 },
            exited: { transform: `matrix(1, 0, 0, 1, 30, 0)`, opacity: 0 }
        }
        return (
            <nav className={this.props.sideCanvas === true ? 'sideDrawer sideDrawer--animate active' : 'sideDrawer sideDrawer--animate'}>
                <div className="bg-active">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 440 254.64" id="lines"><path d="M388.91 166L440 254.51l-293.06.13-51.08-88.49zM293.06 0l51.08 88.49-293 .13L0 .13z"></path></svg>
                </div>
                <div className="nav-active">
                    <div className="nav-unit">
                        <Transition in={this.props.sideCanvas} timeout={duration}>{state => (<h3 onClick={this.props.drawerClickHandler} style={{ ...titleUnitStyle, ...titleUnitTransitionStyles[state] }}><a href='#/service/1'>數位行銷中心</a></h3>)}</Transition>
                        <Transition in={this.props.sideCanvas} timeout={duration + 200}>
                            {state => (
                            <ul style={{ ...listUnitStyle, ...listUnitTransitionStyles[state] }}>
                                <li onClick={this.props.drawerClickHandler}><a href='#/service/1'>口碑行銷</a></li>
                                <li onClick={this.props.drawerClickHandler}><a href='#/service/2'>掠奪行銷</a></li>
                                <li onClick={this.props.drawerClickHandler}><a href='#/service/3'>網紅行銷</a></li>
                                <li onClick={this.props.drawerClickHandler}><a href='#/service/4'>素人體驗</a></li>
                                <li onClick={this.props.drawerClickHandler}><a href='#/service/5'>網路保全監測</a></li>
                                <li onClick={this.props.drawerClickHandler}><a href='#/service/6'>公益行銷</a></li>
                                <li onClick={this.props.drawerClickHandler}><a href='#/service/7'>影音行銷</a></li>
                                <li onClick={this.props.drawerClickHandler}><a href='#/service/8'>iMarketing</a></li>
                            </ul>
                         )}
                        </Transition>
                    </div>
                    <div className="nav-unit">
                        <Transition in={this.props.sideCanvas} timeout={duration + 200}>{state => (<h3 onClick={this.props.drawerClickHandler} style={{ ...titleUnitStyle, ...titleUnitTransitionStyles[state] }}><a href='#/homemade/1'>數位內容中心</a></h3>)}</Transition>
                        <Transition in={this.props.sideCanvas} timeout={duration + 300}>
                            {state => (
                                <ul style={{...listUnitStyle,...listUnitTransitionStyles[state]}}>
                                    <li onClick={this.props.drawerClickHandler}><a href='#/homemade/1'>銀河網路電台</a></li>
                                    <li onClick={this.props.drawerClickHandler}><a href='#/homemade/2'>台灣，你好！</a></li>
                                    <li onClick={this.props.drawerClickHandler}><a href='#/homemade/3'>天天好味</a></li>
                                    <li onClick={this.props.drawerClickHandler}><a href='#/homemade/4'>銀河愛音樂</a></li>
                                </ul>
                            )}
                        </Transition>
                      </div>
                    <div className="nav-unit">
                        <Transition in={this.props.sideCanvas} timeout={duration + 400}>{state => (<h3 onClick={this.props.drawerClickHandler} style={{ ...titleUnitStyle, ...titleUnitTransitionStyles[state] }}><a href='#/exchange/1'>數位交流中心</a></h3>)}</Transition>
                        <Transition in={this.props.sideCanvas} timeout={duration + 500}>
                            {state => (
                                <ul style={{...listUnitStyle,...listUnitTransitionStyles[state]}}>
                                    <li onClick={this.props.drawerClickHandler}><a href='#/exchange/1'>兩岸青年原創金曲大選</a></li>
                                    <li onClick={this.props.drawerClickHandler}><a href='#/exchange/2'>海峽論壇</a></li>
                                    <li onClick={this.props.drawerClickHandler}><a href='#/exchange/3'>兩岸航拍大賽</a></li>
                                </ul>
                            )}
                        </Transition>
                    </div>
                    <div className="nav-unit">
                        <Transition in={this.props.sideCanvas} timeout={duration + 600}>{state => (<h3 onClick={this.props.drawerAboutHandler} style={{ ...titleUnitStyle, ...titleUnitTransitionStyles[state] }}>關於銀河</h3>)}</Transition>
                    </div>
                    <div className="nav-unit">
                        <Transition in={this.props.sideCanvas} timeout={duration + 800}>{state => (<h3 onClick={this.props.drawerContactHandler} style={{ ...titleUnitStyle, ...titleUnitTransitionStyles[state] }}>聯絡我們</h3>)}</Transition>
                    </div>
                    <div className="nav-unit">
                        <Transition in={this.props.sideCanvas} timeout={duration + 1000}>
                            {state => (<a className="special-banner" href="https://www.iwant-in.net/tw/iMarketing/?p=8338" style={{ ...titleUnitStyle, ...titleUnitTransitionStyles[state] }}> </a>)}
                        </Transition>
                    </div>
                </div>
            </nav>
        )
    }
};

export default SideDrawer;