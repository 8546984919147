import React, { Component } from 'react';
import './style.css';

class MainButton extends Component {

    render() {
        const assignedClasses = ['toggleButton'];
        if (this.props.sideAnimate || this.props.aboutAnimate || this.props.contactAnimate) {
            assignedClasses.push('active');
        }
        return (
            <React.Fragment>
                <div className={assignedClasses.join(' ')} onClick={this.props.click}>
                    <div className="toggleButton__wrap">
                        <div className="toggleButton__line"></div>
                        <div className="toggleButton__line"></div>
                        <div className="toggleButton__line"></div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default MainButton;