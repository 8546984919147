import React, { Component } from 'react';
import { withRouter } from "react-router";
import ReactPageScroller from "react-page-scroller";
import PageService from './components/Main/index';
import Dot from '../../components/Dotting/index'
import "bootstrap/dist/css/bootstrap.css";
import "./style.css";
import video1 from "../../video/service-01.webm"
import video2 from "../../video/service-02.webm"
import video3 from "../../video/service-03.webm"
import video4 from "../../video/service-04.webm"
import video5 from "../../video/service-05.webm"
import video6 from "../../video/service-06.webm"
import video7 from "../../video/service-07.webm"

class Service extends Component {

    constructor(props) {
        super(props);
        this.state = {
            anchors: ["口碑行銷", "掠奪行銷", "網紅行銷", "素人體驗", "網路保全監測", "公益行銷", "影音行銷", "iMarketing"],
            dotOpen: true,
            positionTrigger: false,
            locationState: props.location.state,
            windowHistory: window.history.state,
            serviceCommon: [
                {
                    indexNum: 0,
                    animateNum: 1,
                    refName: "service1",
                    animateActive: "enter",
                    videoUrl: video1,
                    mainUnit: {
                        title: "口碑行銷",
                        titleimg: "./image/title/reputation.png",
                        subtitle: "引領企業和網友進行數位溝通\n讓品牌贏得信任"
                    },
                    sidebarUnit: {
                        sidebarOpen: false,
                        sidebarTitle: "口碑行銷",
                        sidebarSubtitle: "\n銀河為全台最大口碑行銷公司",
                        sidebarBlock: [
                            {
                                UnitIndex: 1,
                                UnitTitle: "",
                                UnitContent: "銀河為全台最大口碑行銷公司，擁有21年專業口碑操作經驗，全台灣最能掌握網路脈動的口碑公司！除了我們，您沒有更好的選擇！"
                            }
                        ]
                    },
                    socialUnit: {
                        officialLink: "",
                        facebookLink: "",
                        youtubeLink: ""
                    },
                    contactUnit: {
                        contactTitle: "聯絡方式",
                        contactContent: "(02)6600-7079 分機702 (陳小姐)\nMarketing@iwant-in.net"
                    }
                },
                {
                    indexNum: 1,
                    animateNum: 2,
                    refName: "service2",
                    animateActive: "exit",
                    videoUrl: video2,
                       mainUnit: {
                        title: "掠奪行銷",
                        titleimg: "./image/title/plunder.png",
                        subtitle: "攻佔網路搜尋頁面\n打造完整的企業好評",
                    },
                    sidebarUnit: {
                        sidebarOpen: false,
                        sidebarTitle: "掠奪行銷",
                        sidebarSubtitle: "行銷界獨一無二",
                        sidebarBlock: [
                            {
                                UnitIndex: 1,
                                UnitTitle: "",
                                UnitContent: "行銷界獨一無二，最新的掠奪競品服務、直接搶奪競業生意，拓展您的產品市場！ 欲知詳情現在就聯絡銀河！"
                            }
                        ]
                    },
                    socialUnit: {
                        officialLink: "",
                        facebookLink: "",
                        youtubeLink: ""
                    },
                    contactUnit: {
                        contactTitle: "聯絡方式",
                        contactContent: "(02)6600-7079 分機702 (陳小姐)\nMarketing@iwant-in.net"
                    }
                },
                {
                    indexNum: 2,
                    animateNum: 3,
                    refName: "service3",
                    animateActive: "exit",
                    videoUrl: video3,
                    mainUnit: {
                        title: "網紅行銷",
                        titleimg: "./image/title/celebrity.png",
                        subtitle: "讓時下最夯的網路名人\n作為領頭羊，強力曝光產品",
                    },
                    sidebarUnit: {
                        sidebarOpen: false,
                        sidebarTitle: "網紅行銷",
                        sidebarSubtitle: "",
                        sidebarBlock: [
                            {
                                UnitIndex: 1,
                                UnitTitle: "",
                                UnitContent: "藝人、IG、FB網紅、Youtuber，銀河深知誰才能在茫茫網路消費者中替您的品牌打天下！"
                            }
                        ]
                    },
                    socialUnit: {
                        officialLink: "",
                        facebookLink: "",
                        youtubeLink: ""
                    },
                    contactUnit: {
                        contactTitle: "聯絡方式",
                        contactContent: "(02)6600-7079 分機702 (陳小姐)\nMarketing@iwant-in.net"
                    }
                },
                {
                    indexNum: 3,
                    animateNum: 4,
                    refName: "service4",
                    animateActive: "exit",
                    videoUrl: video4,
                    mainUnit: {
                        title: "素人體驗",
                        titleimg: "./image/title/amateur.png",
                        subtitle: "龐大的網路素人分享團\n當你的見證人\n讓體驗心得隨處可見",
                    },
                    sidebarUnit: {
                        sidebarOpen: false,
                        sidebarTitle: "素人體驗",
                        sidebarSubtitle: "",
                        sidebarBlock: [
                            {
                                UnitIndex: 1,
                                UnitTitle: "",
                                UnitContent: "銀河首創全球第一個網路試用平台，為市面上最直接、大量傳散產品好評心得的行銷利器"
                            }
                        ]
                    },
                    socialUnit: {
                        officialLink: "https://www.itry.com.tw",
                        facebookLink: "https://www.facebook.com/iTRY.tw/",
                        youtubeLink: ""
                    },
                    contactUnit: {
                        contactTitle: "聯絡方式",
                        contactContent: "(02)6600-0357 分機887\ncontact@itry.com.tw"
                    }
                },
                {
                    indexNum: 4,
                    animateNum: 5,
                    refName: "service5",
                    animateActive: "exit",
                    videoUrl: video5,
                    mainUnit: {
                        title: "網路保全監測",
                        titleimg: "./image/title/preservation.png",
                        subtitle: "掌握網友言論，每日監測消費者意見\n及時平反網路負評\n搶先建立口碑贏得先機",
                    },
                    sidebarUnit: {
                        sidebarOpen: false,
                        sidebarTitle: "網路保全監測",
                        sidebarSubtitle: "",
                        sidebarBlock: [
                            {
                                UnitIndex: 1,
                                UnitTitle: "",
                                UnitContent: "銀河不會放過任何一個偷說您壞話的網友，只有我們的網路監測系統，\n能完全掌握品牌的網路言論！您唯一的網路輿論守門神！"
                            }
                        ]
                    },
                    socialUnit: {
                        officialLink: "",
                        facebookLink: "",
                        youtubeLink: ""
                    },
                    contactUnit: {
                        contactTitle: "聯絡方式",
                        contactContent: "(02)66007079 分機702 (陳小姐)\nMarketing@iwant-in.net"
                    }
                },
                {
                    indexNum: 5,
                    animateNum: 6,
                    refName: "service6",
                    animateActive: "exit",
                    videoUrl: video6,
                    mainUnit: {
                        title: "公益行銷",
                        titleimg: "./image/title/charity.png",
                        subtitle: "如果你是熱愛這片土地的台灣人\n加入銀河接棒環島的行列!",
                    },
                    sidebarUnit: {
                        sidebarOpen: false,
                        sidebarTitle: "公益行銷",
                        sidebarSubtitle: "",
                        sidebarBlock: [
                            {
                                UnitIndex: 1,
                                UnitTitle: "",
                                UnitContent: "加入贊助台灣人環島的行列！ 讓30萬粉絲帶動全台灣各鄉鎮，替您傳散品牌精神！"
                            }
                        ]
                    },
                    socialUnit: {
                        officialLink: "https://www.twnihao.com.tw/",
                        facebookLink: "https://www.facebook.com/twnihao",
                        youtubeLink: "https://www.youtube.com/c/TwnihaoTwhello"
                    },
                    contactUnit: {
                        contactTitle: "聯絡方式",
                        contactContent: "(02)6600-7079 分機702 (陳小姐)\nMarketing@iwant-in.net"
                    }
                },
                {
                    indexNum: 6,
                    animateNum: 7,
                    refName: "service7",
                    animateActive: "exit",
                    videoUrl: video7,
                    mainUnit: {
                        title: "影音行銷",
                        titleimg: "./image/title/audio.png",
                        subtitle: "不管是哪種產品\n銀河都能為您量身打造吸睛的視覺行銷\n快速引起網友興趣",
                    },
                    sidebarUnit: {
                        sidebarOpen: false,
                        sidebarTitle: "影音行銷",
                        sidebarSubtitle: "",
                        sidebarBlock: [
                            {
                                UnitIndex: 1,
                                UnitTitle: "",
                                UnitContent: "無論您是哪種產品，想要用影片快速抓住網友眼球，誘發產品需求，直接找銀河就對了！"
                            }
                        ]
                    },
                    socialUnit: {
                        officialLink: "",
                        facebookLink: "",
                        youtubeLink: ""
                    },
                    contactUnit: {
                        contactTitle: "聯絡方式",
                        contactContent: "(02)66007079 分機702 (陳小姐)\nMarketing@iwant-in.net"
                    }
                },
                {
                    indexNum: 7,
                    animateNum: 8,
                    refName: "service8",
                    animateActive: "exit",
                    videoUrl: "https://www.iwant-in.net/video/service-08.mp4",
                    mainUnit: {
                        title: "iMarketing",
                        titleimg: "./image/title/imarketing.png",
                        subtitle: "數位行銷推廣地方人事物\n活絡地方創生能量",
                    },
                    sidebarUnit: {
                        sidebarOpen: false,
                        sidebarTitle: "iMarketing",
                        sidebarSubtitle: "",
                        sidebarBlock: [
                            {
                                UnitIndex: 1,
                                UnitTitle: "",
                                UnitContent: "行銷人不能錯過，由銀河專業領航員集結寶貴網路行銷經驗，分析集錦成的數位行銷知識庫。"
                            }
                        ]
                    },
                    socialUnit: {
                        officialLink: "https://www.iwant-in.net/tw/iMarketing",
                        facebookLink: "",
                        youtubeLink: ""
                    },
                    contactUnit: {
                        contactTitle: "聯絡方式",
                        contactContent: "(02)66007079 分機702 (陳小姐)\nMarketing@iwant-in.net"
                    }
                }
            ]
        }

    }
    dotOpenHandler = () => {
        this.setState(prev => ({
            dotOpen: !prev.dotOpen
        }))
    };
    sidebarClickHandler = () => {
        this.setState(prev => ({
            dotOpen: !prev.dotOpen,
            positionTrigger: !prev.positionTrigger
        }))
    };
    goToPage = (eventKey) => {
        this.reactPageScroller.goToPage(eventKey);
    };

    pageOnChange = (pageNumber) => {

        if (this.state.positionTrigger) {
            this.sidebarClickHandler();
        }
        /* 換頁時控制state來啟動動態效果 */
        const pageNum = pageNumber - 1;
        this.setState(prev => ({
            locationState: pageNumber,
            serviceCommon: prev.serviceCommon.map(serviceCommon =>
                serviceCommon.indexNum === pageNum ?
                    { ...serviceCommon, animateActive: 'enter' } :
                    { ...serviceCommon, animateActive: 'exit' })
        }))
    }

    componentDidMount() {
        /* loading時讀取 */
        let propsParamsMount = Number(this.props.match.params.id);
        let locationStateRender = '#/service/' + Number(this.state.locationState);
        if (Number(this.state.locationState) !== 0 && !Number.isNaN(this.state.locationState)) {
            if (this.state.locationState === undefined) {
                window.history.pushState('#/service/1', "", '#/service/1');
            } else {
                window.history.pushState(locationStateRender, "", locationStateRender);
            }
        } else if (Number.isNaN(this.state.locationState)) {
            window.history.pushState('#/service/1', "", '#/service/1');
        }
        this.goToPage(propsParamsMount - 1);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let stateParamsUpdate = this.state.locationState;
        let stateParamsPreUpdate = prevState.locationState;
        /* 變化state時迴避id變化 */
        if (stateParamsPreUpdate !== undefined && stateParamsUpdate !== undefined) {
            /* 迴避兩者皆為空值的狀態 */
            if (stateParamsPreUpdate !== stateParamsUpdate) {
                /* 若與初始值不同 */
                this.goToPage(stateParamsUpdate);
            } else {
                if (this.props.history.action === "PUSH") {
                    if (prevProps.location.state !== this.props.location.state) {
                        let NumberState = Number(this.props.location.state);
                        this.goToPage(NumberState - 1);
                    }
                }
            }
        }

    }

    render() {
        let locationStateRender = '#/service/' + Number(this.state.locationState);
        if (Number(this.state.locationState) !== 0 && !Number.isNaN(this.state.locationState)) {
            if (this.state.locationState === undefined) {
                window.history.pushState('#/service/1', "", '#/service/1');
            } else {
                window.history.pushState(locationStateRender, "", locationStateRender);
            }
        } else if (Number.isNaN(this.state.locationState)) {
            window.history.pushState('#/service/1', "", '#/service/1');
        }
        return(
            <React.Fragment>
                <ReactPageScroller ref={c => this.reactPageScroller = c} pageOnChange={this.pageOnChange}>
                    {this.state.serviceCommon.map((service, key) => {
                        return <PageService
                            key={key}
                            animateNum={service.animateNum}
                            animateActive={service.animateActive}
                            refName={service.refName}
                            videoUrl={service.videoUrl}
                            mainUnit={service.mainUnit}
                            sidebarUnit={service.sidebarUnit}
                            contactUnit={service.contactUnit}
                            socialUnit={service.socialUnit}
                            dotOpen={this.dotOpenHandler}
                            positionTrigger={this.state.positionTrigger}
                            sidebarClickHandler={this.sidebarClickHandler} />
                    })}
                </ReactPageScroller>
                <Dot anchors={this.state.anchors} goToPage={this.goToPage} dotOpen={this.state.dotOpen} innerLink={this.state.innerLink}></Dot>
            </React.Fragment>
        );
    }
}

export default withRouter(Service);