import React, { Component } from 'react';
import { withRouter } from "react-router";
import ReactPageScroller from "react-page-scroller";
import PageExchange from './components/Main/index';
import Dot from '../../components/Dotting/index'
import "bootstrap/dist/css/bootstrap.css";
import "./style.css"
import PageTitle01 from "./images/title/youthsong.png";
import PageTitle02 from "./images/title/forum.png";
import PageTitle03 from "./images/title/airborne.png";
import video1 from "../../video/exchange-01.webm"
import video2 from "../../video/exchange-02.webm"
import video3 from "../../video/exchange-03.webm"

class Exchange extends Component {

    constructor(props) {
        super(props);
        this.state = {
            anchors: ["兩岸青年原創金曲大選", "海峽論壇", "兩岸航拍大賽"],
            dotOpen: true,
            positionTrigger: false,
            locationState: Number(props.location.state),
            enterHistory: Number(props.match.params.id),
            exchangeCommon: [
                {
                    indexNum: 0,
                    animateNum: 1,
                    refName: "exchange1",
                    animateActive: "enter",
                    videoUrl: video1,
                    mainUnit: {
                        title: "兩岸青年原創金曲大選",
                        titleimg: PageTitle01,
                        subtitle: "兩岸共同合作承辦，\n銀河支持音樂創作才華的青年站上巔峰",
                    },
                    sidebarUnit: {
                        sidebarOpen: false,
                        sidebarTitle: "兩岸青年\n原創金曲大選",
                        sidebarSubtitle: "",
                        sidebarBlock: [
                            {
                                UnitIndex: 1,
                                UnitTitle: "",
                                UnitContent: "兩岸青年原創金曲大選，為兩岸間唯一的原創金曲賽事，\n在音樂的切磋中，激盪出創意的音浪，\n並挖掘源源不絕的原創音樂人才。銀河支持有音樂創作才華的青年站上巔峰挑戰，\n希望在這個人才輩出的時代，能聽見音樂持續被創作！"
                            },
                            {
                                UnitIndex: 2,
                                UnitTitle: "",
                                UnitContent: "此活動由兩岸共同合作承辦"
                            },
                            {
                                UnitIndex: 3,
                                UnitTitle: "",
                                UnitContent: "主辦：\n中央廣播電視總台\n中華青年數位文創交流協會（台灣）\n"
                            },
                            {
                                UnitIndex: 4,
                                UnitTitle: "",
                                UnitContent: "協辦：\n中央廣播電視總台央廣對台灣節目中心\n銀河互動網路股份有限公司（台灣）\n愛音斯坦FM"
                            }

                        ]
                    },
                    socialUnit: {
                        officialLink: "https://www.youth-song.com",
                        facebookLink: "",
                        youtubeLink: ""
                    },
                    contactUnit: {
                        contactTitle: "聯絡方式",
                        contactContent: "(02)6600-7079 分機105"
                    }
                },
                {
                    indexNum: 1,
                    animateNum: 2,
                    refName: "exchange2",
                    animateActive: "exit",
                    videoUrl: video2,
                    mainUnit: {
                        title: "海峽論壇",
                        titleimg: PageTitle02,
                        subtitle: "銀河參與兩岸民間\n最大的盛事\"海峽論壇\"",
                    },
                    sidebarUnit: {
                        sidebarOpen: false,
                        sidebarTitle: "海峽論壇",
                        sidebarSubtitle: "",
                        sidebarBlock: [
                            {
                                UnitIndex: 1,
                                UnitTitle: "",
                                UnitContent: "海峽論壇為兩岸民間交流最大活動，銀河參加並連續主辦三屆海峽兩岸青少年新媒體文創論壇，\n為兩岸華人大學院校數位畢業聯展，逐漸揭開序幕"
                            },
                            {
                                UnitIndex: 2,
                                UnitTitle: "",
                                UnitContent: "合作編劇、拍攝微電影，並於海峽兩岸青少年微電影主題峰會大放異彩，\n邁開了兩岸文創新意發展的一大步！"
                            }
                        ]
                    },
                    socialUnit: {
                        officialLink: "",
                        facebookLink: "",
                        youtubeLink: ""
                    },
                    contactUnit: {
                        contactTitle: "聯絡方式",
                        contactContent: "(02)66007079 分機105"
                    }
                },
                {
                    indexNum: 2,
                    animateNum: 3,
                    refName: "exchange3",
                    animateActive: "exit",
                    videoUrl: video3,
                    mainUnit: {
                        title: "兩岸航拍大賽",
                        titleimg: PageTitle03,
                        subtitle: "兩岸共同承辦無人機航拍大賽\n提供青年一個發光發熱的平台",
                    },
                    sidebarUnit: {
                        sidebarOpen: false,
                        sidebarTitle: "兩岸航拍大賽",
                        sidebarSubtitle: "",
                        sidebarBlock: [
                            {
                                UnitIndex: 1,
                                UnitTitle: "",
                                UnitContent: "銀河規劃活動所需元素，並協助空拍愛好者參與大賽，也促進了兩岸空拍專業交流學習的機會\n除了增進創意上的交流，呈現兩岸民眾眼中的美景\n也提供精於無人機航拍的青年一個發光發熱的舞台！"
                            },
                            {
                                UnitIndex: 2,
                                UnitTitle: "",
                                UnitContent: "此活動由兩岸共同合作承辦，並由中國電視藝術家協會指導"
                            },
                            {
                                UnitIndex: 3,
                                UnitTitle: "",
                                UnitContent: "主辦：\n中華文化發展促進會\n中國遙感應用協會"
                            },
                            {
                                UnitIndex: 4,
                                UnitTitle: "",
                                UnitContent: "協辦：\n中國無人系統產業聯盟\n央視網等......"
                            },
                            {
                                UnitIndex: 5,
                                UnitTitle: "",
                                UnitContent: "承辦：銀河互動網路股份有限公司（台灣）"
                            }
                        ]
                    },
                    socialUnit: {
                        officialLink: "",
                        facebookLink: "",
                        youtubeLink: ""
                    },
                    contactUnit: {
                        contactTitle: "聯絡方式",
                        contactContent: "(02)66007079 分機105"
                    }
                }
            ]
        }
    }

    dotOpenHandler = () => {
        this.setState(prev => ({
            dotOpen: !prev.dotOpen
        }))
    };
    sidebarClickHandler = () => {
        this.setState(prev => ({
            dotOpen: !prev.dotOpen,
            positionTrigger: !prev.positionTrigger
        }))
    };
    goToPage = (eventKey) => {
        this.reactPageScroller.goToPage(eventKey);
    };

    pageOnChange = (pageNumber) => {
        if (this.state.positionTrigger) {
            this.sidebarClickHandler();
        }
        /* 換頁時控制state來啟動動態效果 */
        const pageNum = pageNumber - 1;
        this.setState(prev => ({
            locationState: pageNumber,
            exchangeCommon: prev.exchangeCommon.map(exchangeCommon =>
                exchangeCommon.indexNum === pageNum ?
                    { ...exchangeCommon, animateActive: 'enter' } :
                    { ...exchangeCommon, animateActive: 'exit' })
        }))
    }

    componentDidMount() {
        /* loading時讀取 */
        let propsParamsMount = Number(this.props.match.params.id);
        let locationStateRender = '#/exchange/' + Number(this.state.locationState);
        if (Number(this.state.locationState) !== 0 && !Number.isNaN(this.state.locationState)) {
            if (this.state.locationState === undefined) {
                window.history.pushState('#/exchange/1', "", '#/exchange/1');
            } else {
                window.history.pushState(locationStateRender, "", locationStateRender);
            }
        } else if (Number.isNaN(this.state.locationState)) {
            window.history.pushState('#/exchange/1', "", '#/exchange/1');
        }
        this.goToPage(propsParamsMount - 1);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let stateParamsUpdate = this.state.locationState;
        let stateParamsPreUpdate = prevState.locationState;
        /* 變化state時迴避id變化 */
        if (stateParamsPreUpdate !== undefined && stateParamsUpdate !== undefined) {
            /* 迴避兩者皆為空值的狀態 */
            if (stateParamsPreUpdate !== stateParamsUpdate) {
                /* 若與初始值不同 */
                this.goToPage(stateParamsUpdate);
            } else {
                /* 若兩者不為空值的狀態 */
                if (this.props.history.action === "PUSH") {
                    /* 若開啟過選單 */
                    if (prevProps.location.state !== this.props.location.state) {
                        /* 若前後頁面有變化 */
                        let NumberState = Number(this.props.location.state);
                        this.goToPage(NumberState - 1);
                    }
                }
            }
        }

    }

    render() {
        let locationStateRender = '#/exchange/' + Number(this.state.locationState);
        if (Number(this.state.locationState) !== 0 && !Number.isNaN(this.state.locationState)) {
            if (this.state.locationState === undefined) {
                window.history.pushState('#/exchange/1', "", '#/exchange/1');
            } else {
                window.history.pushState(locationStateRender, "", locationStateRender);
            }
        } else if (Number.isNaN(this.state.locationState)) {
            window.history.pushState('#/exchange/1', "", '#/exchange/1');
        }
        return(
            <React.Fragment>
                <ReactPageScroller ref={c => this.reactPageScroller = c} pageOnChange={this.pageOnChange}>
                    {this.state.exchangeCommon.map((exchange, key) => {
                        return <PageExchange
                            key={key}
                            animateNum={exchange.animateNum}
                            animateActive={exchange.animateActive}
                            refName={exchange.refName}
                            videoUrl={exchange.videoUrl}
                            mainUnit={exchange.mainUnit}
                            sidebarUnit={exchange.sidebarUnit}
                            contactUnit={exchange.contactUnit}
                            socialUnit={exchange.socialUnit}
                            dotOpen={this.dotOpenHandler}
                            positionTrigger={this.state.positionTrigger}
                            sidebarClickHandler={this.sidebarClickHandler} />
                    })}
                </ReactPageScroller>
                <Dot anchors={this.state.anchors} goToPage={this.goToPage} dotOpen={this.state.dotOpen} innerLink={this.state.innerLink}></Dot>
            </React.Fragment>
        );
    }
}



export default withRouter(Exchange);