import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import posed from 'react-pose';
import SplitText from 'react-pose-text';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DotLink from '../../../../components/DottingLink/index'
import "bootstrap/dist/css/bootstrap.css";
import "./style.css";

const charPoses = {
    exit: { opacity: 0, x: -20 },
    enter: { opacity: 1, x: 0, transition: ({ charIndex }) => ({ type: 'tween', duration: 800, delay: 400 + charIndex * 200 }) }
};
const charPoses2 = {
    exit: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1, transition: ({ charIndex }) => ({ duration: 400, delay: (charIndex + 1) * 40 }) }
};
const BgPoses = posed.div({
    exit: { opacity: 0, z: '25%', transition: () => ({ duration: 500, type: 'tween'}) },
    enter: { opacity: 1, z: 0, transition: () => ({ duration: 1000, type: 'tween'}) }
});
const Content = posed.div({
    enter: { staggerChildren: 400 },
    exit: { staggerChildren: 20, staggerDirection: -1 }
});
const P = posed.p({
    enter: { y: 0, opacity: 1, transition: () => ({ type: 'tween', delay: 500 }) },
    exit: { y: 10, opacity: 0 }
});
const Btn = posed.p({
    enter: { y: 0, opacity: 1, transition: () => ({ type: 'tween', delay: 1750 }) },
    exit: { y: 5, opacity: 0 }
});


class Pageunit extends Component {

    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.bgRef = React.createRef();
        this.state = {
            videoActive: false,
            updateCheck: this.props.animateNum
        }
    }

    componentDidMount() {
        let nowVideo = this.videoRef.current;
        if (this.state.updateCheck === 1 && this.props.animateCt === 'enter') {
            nowVideo.play()
        }
    }
    componentDidUpdate() {
        let nowVideo = this.videoRef.current;
        if (this.state.updateCheck === this.props.animateNum && this.props.animateCt === 'enter') {
            nowVideo.play()
        } else {
            nowVideo.pause()
        }
    }
    render () {
        return (
            <div className={`section section-${this.props.animateNum}`}>
                <Container fluid>
                    <Row>
                        <Col xs="12" lg="6" className="sec-left">
                            <div className="sec-video object-fit__container">
                                <video className="background-video object-fit__cover" ref={this.videoRef} id={`cover-${this.props.animateNum}`} data-object-fit="cover"  preload="auto" loop muted playsInline>
                                    <source src={this.props.url} type="video/mp4" />
                                    <source src={this.props.url} type="video/ogg" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </Col>
                        <Col xs="12" lg="6" className="sec-right">

                            <div className="sec-main">
                                <h2><SplitText initialPose="exit" pose={this.props.animateCt} charPoses={charPoses}>{this.props.title}</SplitText></h2>
                                <span className="sec-subtitle"><SplitText initialPose="exit" pose={this.props.animateCt} charPoses={charPoses2}>{this.props.subtitle}</SplitText></span>
                                <span className="sec-seperate">／</span>
                                <Content initialPose="exit" pose={this.props.animateCt} className="sec-content">
                                    <P initialPose="exit">{this.props.contentlineOne}</P>
                                    <P initialPose="exit">{this.props.contentlineTwo}</P>
                                    <P initialPose="exit">{this.props.contentlineThree}</P>
                                    <P initialPose="exit">{this.props.contentlineFour}</P>
                                    <P initialPose="exit">{this.props.contentlineFive}</P>
                                </Content>
                                {this.props.animateNum !== 4 ? (
                                    <Btn initialPose="exit" pose={this.props.animateCt} className="sec-button"><NavLink to={`${this.props.innerLink}/1`}>詳 細 內 容</NavLink></Btn>) : null}
                            </div>
                            <BgPoses initialPose="exit" pose={this.props.animateCt} className="background"></BgPoses>
                        </Col>
                    </Row>
                </Container>
                {this.props.animateNum!==4 ? (
                <div className="arrowDown" onClick={() => { this.props.videoScrollDown(this.props.animateNum) }}>
                    <div className="arrow"></div>
                </div>) : null}
                <DotLink anchors={this.props.anchors} animateNum={this.props.animateNum} innerLink={this.props.innerLink}></DotLink>
            </div>

        );
    }
};


export default Pageunit;